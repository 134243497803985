import React from 'react'
import { Link } from 'gatsby'
import ReactWOW from 'react-wow';
const Footer = () => {
  return(
    <footer className="new_footer_area bg_color">
        <div className="new_footer_top">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <ReactWOW animation="fadeInLeft" delay="0.2s">
                        <div className="f_widget company_widget">
                            <h3 className="f-title f_600 t_color f_size_18">Sign Up</h3>
                            <p>Signup now - it's quick and easy to get started and no credit card needed!</p>
                            <Link to="/signup">
                                <button type="submit" className="btn_hover btn_get">Get Started Now</button>
                            </Link>
                        </div>
                        </ReactWOW>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <ReactWOW animation="fadeInLeft" delay="0.4s">
                        <div className="f_widget about-widget pl_70">
                            <h3 className="f-title f_600 t_color f_size_18">About</h3>
                            <ul className="list-unstyled f_list">
                                {/* <li><Link to="/">What is Workhub</Link></li> */}
                                <li><Link to="/features/">Features</Link></li>
                                <li><Link to="/features/overview/">Features Overview</Link></li>
                                <li><Link to="/pricing/">Pricing</Link></li>
                                <li><Link to="/about/story/">Our Story</Link></li>
                                <li><Link to="/about/team/">Our Team</Link></li>
                                <li><a href="https://www.workerid.com" target="_blank" rel="noopener noreferrer">WorkerID</a></li>
                                
                                {/* <li><Link to="/">Company Info</Link></li>
                                <li><Link to="/">Careers</Link></li> */}

                            </ul>
                        </div>
                        </ReactWOW>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                    <ReactWOW animation="fadeInLeft" delay="0.6s">
                    <div className="f_widget about-widget pl_70">
                            <h3 className="f-title f_600 t_color f_size_18">Resources</h3>
                            <ul className="list-unstyled f_list">
                                <li><Link to="/about/news/">Release Notes</Link></li>
                                <li><a href="http://help.workhub.com/en/" target="_blank" rel="noopener noreferrer">Help</a></li>
                                <li><Link to="/features/training/">Training Courses</Link></li>
                                <li><Link to="/resources/partners/">Partners</Link></li>
                                <li><Link to="/resources/blog/">Workhub Blog</Link></li>
                                <li><a href="https://www.youtube.com/channel/UC7jQkB2O_sVOUsylseAp9RA" target="_blank" rel="noopener noreferrer">WorkhubU</a></li>
                                <li><a href="https://portal.productboard.com/workhub/" target="_blank" rel="noopener noreferrer">Product Roadmap</a></li>
                            </ul>
                        </div>
                            </ReactWOW>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                    <ReactWOW animation="fadeInLeft" delay="0.8s">
                        <div className="f_widget social-widget">
                            <h3 className="f-title f_600 t_color f_size_18">Contact </h3>
                            <div className="widget-wrap">
                                <h4 style={{fontSize: '1rem'}}>Support:</h4>
                                <p className="f_400 f_p f_size_15 mb-0 mt-2"><a href="mailto:support@workhub.com" className="f_400" style={{height: '48px'}}>support@workhub.com</a></p>
                                <p className="f_400 f_p f_size_15 mb-0 mt-2" ><a href="tel:1-888-668-6403" className="f_400">1-888-668-6403</a></p>
                                <br />
                                <h4 style={{fontSize: '1rem'}}>Sales:</h4>
                                <p className="f_400 f_p f_size_15 mb-0 mt-2"><a href="mailto:sales@workhub.com" className="f_400" style={{height: '48px'}}>sales@workhub.com</a></p>
                                <p className="f_400 f_p f_size_15 mb-0 mt-2"><a href="tel:1-866-668-6402" className="f_400">1-866-668-6402</a></p>
                            </div>
                            <br />
                            <div className="f_social_icon">
                                <a href="https://www.facebook.com/workhubsoftware/" target="_blank" rel="noopener noreferrer" className="ti-facebook" aria-label="Workhub Facebook"><img style={{display: 'inline'}}src="/app-icons/layout-icons/facebook.svg" alt="Facebook Icon"/></a>
                                <a href="https://instagram.com/workhubsoftware" target="_blank" rel="noopener noreferrer" className="ti-instagram" aria-label="Workhub Instagram"><img style={{display: 'inline'}} src="/app-icons/layout-icons/instagram.svg" alt="Instagram Icon"></img></a>
                                <a href="https://twitter.com/workhubsoftware" target="_blank" rel="noopener noreferrer" className="ti-twitter-alt" aria-label="Workhub Twitter"><img style={{display: 'inline'}}src="/app-icons/layout-icons/x.svg" alt="X Icon"/></a>
                                <a href="https://www.linkedin.com/company/3741519" target="_blank" rel="noopener noreferrer" className="ti-linkedin" aria-label="Workhub LinkedIn"><img style={{display: 'inline'}}src="/app-icons/layout-icons/linkedin.svg" alt="LinkedIn Icon"/></a>
                                <a href="https://www.youtube.com/channel/UC7jQkB2O_sVOUsylseAp9RA" target="_blank" rel="noopener noreferrer" className="ti-youtube" aria-label="Workhub Youtube"><img style={{display: 'inline'}}src="/app-icons/layout-icons/youtube-small.svg" alt="Youtube Icons"/></a>
                            </div>
                                                            
                        </div>
                            </ReactWOW>
                    </div>
                </div>
            </div>
            {/* <div className="footer_bg">
                <div className="footer_bg_one"></div>
                <div className="footer_bg_two"></div>
            </div> */}
        </div>
        <div className="footer_bottom">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-8 col-sm-8" style={{display: 'flex', flexDirection: 'flex-row', flexWrap: 'wrap'}}>
                        <div className="footer-copy"><p>© Workhub Software Corp. 2023 All rights reserved.</p></div>
                        <div className="footer-links">
                            <Link className="mx-2 f_400" to="/privacy" style={{width: 'max-content'}}>Privacy Policy</Link>
                            <p className=" f_400 footer-pipe">|</p>
                            <Link className="mx-2 f_400" to="/terms/" style={{width: 'max-content'}}>Terms & Conditions</Link>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-4 text-right">
                        <p>Made with <img src="/app-icons/layout-icons/heart-black.svg" alt="Black Heart"/> by <Link to="/">Workhub</Link></p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  )
}

export default Footer